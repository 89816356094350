import { default as authrFT4p3If81Meta } from "/app/src/pages/auth.vue?macro=true";
import { default as callback4gcqsCXMZHMeta } from "/app/src/pages/callback.vue?macro=true";
import { default as _91id_93JxKQe7QJQZMeta } from "/app/src/pages/friends/[id].vue?macro=true";
import { default as indexknsgveVdUEMeta } from "/app/src/pages/friends/index.vue?macro=true";
import { default as _91id_93955hjA42sPMeta } from "/app/src/pages/games/[id].vue?macro=true";
import { default as indexkeNSVYatIaMeta } from "/app/src/pages/games/index.vue?macro=true";
import { default as indexjyKii2RwThMeta } from "/app/src/pages/home/index.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as matchmakingGDfU2CBnaoMeta } from "/app/src/pages/matchmaking.vue?macro=true";
import { default as _91id_93o9Hpu2YWO8Meta } from "/app/src/pages/messages/[id].vue?macro=true";
import { default as indexlQiYmhH1fKMeta } from "/app/src/pages/messages/index.vue?macro=true";
import { default as platformsOdYyblfpnkMeta } from "/app/src/pages/onboarding/platforms.vue?macro=true";
import { default as avatarvvLNj19xaYMeta } from "/app/src/pages/onboarding/profile/avatar.vue?macro=true";
import { default as index6vpqCJ1gfHMeta } from "/app/src/pages/onboarding/profile/index.vue?macro=true";
import { default as _91id_93LuHOulKMLnMeta } from "/app/src/pages/profiles/[id].vue?macro=true";
import { default as indexTXO73hmFNkMeta } from "/app/src/pages/settings/index.vue?macro=true";
export default [
  {
    name: authrFT4p3If81Meta?.name ?? "auth",
    path: authrFT4p3If81Meta?.path ?? "/auth",
    meta: authrFT4p3If81Meta || {},
    alias: authrFT4p3If81Meta?.alias || [],
    redirect: authrFT4p3If81Meta?.redirect,
    component: () => import("/app/src/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: callback4gcqsCXMZHMeta?.name ?? "callback",
    path: callback4gcqsCXMZHMeta?.path ?? "/callback",
    meta: callback4gcqsCXMZHMeta || {},
    alias: callback4gcqsCXMZHMeta?.alias || [],
    redirect: callback4gcqsCXMZHMeta?.redirect,
    component: () => import("/app/src/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JxKQe7QJQZMeta?.name ?? "friends-id",
    path: _91id_93JxKQe7QJQZMeta?.path ?? "/friends/:id()",
    meta: _91id_93JxKQe7QJQZMeta || {},
    alias: _91id_93JxKQe7QJQZMeta?.alias || [],
    redirect: _91id_93JxKQe7QJQZMeta?.redirect,
    component: () => import("/app/src/pages/friends/[id].vue").then(m => m.default || m)
  },
  {
    name: indexknsgveVdUEMeta?.name ?? "friends",
    path: indexknsgveVdUEMeta?.path ?? "/friends",
    meta: indexknsgveVdUEMeta || {},
    alias: indexknsgveVdUEMeta?.alias || [],
    redirect: indexknsgveVdUEMeta?.redirect,
    component: () => import("/app/src/pages/friends/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93955hjA42sPMeta?.name ?? "games-id",
    path: _91id_93955hjA42sPMeta?.path ?? "/games/:id()",
    meta: _91id_93955hjA42sPMeta || {},
    alias: _91id_93955hjA42sPMeta?.alias || [],
    redirect: _91id_93955hjA42sPMeta?.redirect,
    component: () => import("/app/src/pages/games/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkeNSVYatIaMeta?.name ?? "games",
    path: indexkeNSVYatIaMeta?.path ?? "/games",
    meta: indexkeNSVYatIaMeta || {},
    alias: indexkeNSVYatIaMeta?.alias || [],
    redirect: indexkeNSVYatIaMeta?.redirect,
    component: () => import("/app/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexjyKii2RwThMeta?.name ?? "home",
    path: indexjyKii2RwThMeta?.path ?? "/home",
    meta: indexjyKii2RwThMeta || {},
    alias: indexjyKii2RwThMeta?.alias || [],
    redirect: indexjyKii2RwThMeta?.redirect,
    component: () => import("/app/src/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: matchmakingGDfU2CBnaoMeta?.name ?? "matchmaking",
    path: matchmakingGDfU2CBnaoMeta?.path ?? "/matchmaking",
    meta: matchmakingGDfU2CBnaoMeta || {},
    alias: matchmakingGDfU2CBnaoMeta?.alias || [],
    redirect: matchmakingGDfU2CBnaoMeta?.redirect,
    component: () => import("/app/src/pages/matchmaking.vue").then(m => m.default || m)
  },
  {
    name: _91id_93o9Hpu2YWO8Meta?.name ?? "messages-id",
    path: _91id_93o9Hpu2YWO8Meta?.path ?? "/messages/:id()",
    meta: _91id_93o9Hpu2YWO8Meta || {},
    alias: _91id_93o9Hpu2YWO8Meta?.alias || [],
    redirect: _91id_93o9Hpu2YWO8Meta?.redirect,
    component: () => import("/app/src/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlQiYmhH1fKMeta?.name ?? "messages",
    path: indexlQiYmhH1fKMeta?.path ?? "/messages",
    meta: indexlQiYmhH1fKMeta || {},
    alias: indexlQiYmhH1fKMeta?.alias || [],
    redirect: indexlQiYmhH1fKMeta?.redirect,
    component: () => import("/app/src/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: platformsOdYyblfpnkMeta?.name ?? "onboarding-platforms",
    path: platformsOdYyblfpnkMeta?.path ?? "/onboarding/platforms",
    meta: platformsOdYyblfpnkMeta || {},
    alias: platformsOdYyblfpnkMeta?.alias || [],
    redirect: platformsOdYyblfpnkMeta?.redirect,
    component: () => import("/app/src/pages/onboarding/platforms.vue").then(m => m.default || m)
  },
  {
    name: avatarvvLNj19xaYMeta?.name ?? "onboarding-profile-avatar",
    path: avatarvvLNj19xaYMeta?.path ?? "/onboarding/profile/avatar",
    meta: avatarvvLNj19xaYMeta || {},
    alias: avatarvvLNj19xaYMeta?.alias || [],
    redirect: avatarvvLNj19xaYMeta?.redirect,
    component: () => import("/app/src/pages/onboarding/profile/avatar.vue").then(m => m.default || m)
  },
  {
    name: index6vpqCJ1gfHMeta?.name ?? "onboarding-profile",
    path: index6vpqCJ1gfHMeta?.path ?? "/onboarding/profile",
    meta: index6vpqCJ1gfHMeta || {},
    alias: index6vpqCJ1gfHMeta?.alias || [],
    redirect: index6vpqCJ1gfHMeta?.redirect,
    component: () => import("/app/src/pages/onboarding/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LuHOulKMLnMeta?.name ?? "profiles-id",
    path: _91id_93LuHOulKMLnMeta?.path ?? "/profiles/:id()",
    meta: _91id_93LuHOulKMLnMeta || {},
    alias: _91id_93LuHOulKMLnMeta?.alias || [],
    redirect: _91id_93LuHOulKMLnMeta?.redirect,
    component: () => import("/app/src/pages/profiles/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTXO73hmFNkMeta?.name ?? "settings",
    path: indexTXO73hmFNkMeta?.path ?? "/settings",
    meta: indexTXO73hmFNkMeta || {},
    alias: indexTXO73hmFNkMeta?.alias || [],
    redirect: indexTXO73hmFNkMeta?.redirect,
    component: () => import("/app/src/pages/settings/index.vue").then(m => m.default || m)
  }
]