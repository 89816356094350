import { defineStore } from 'pinia'

interface tabs {
  name: string
  path: string
  hash: string
  current: boolean
}

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    tabs: [] as tabs[],
    rightBarOpen: false,
    inMessagesPage: false,
    bottomBar: true,
    partyOpen: false,
    background:
      'radial-gradient(at 20% 60%, hsla(215,95%,64%,1) 0, transparent 33%), radial-gradient(at 90% 60%, hsla(106,85%,63%,1) 0, transparent 27%), radial-gradient(at 99% 20%, hsla(96,59%,72%,1) 0, transparent 38%)',
  }),
  actions: {
    openRightBar() {
      if (!this.inMessagesPage) {
        this.rightBarOpen = !this.rightBarOpen
      }
    },
    setPartyOpen(isOpen: boolean) {
      this.partyOpen = isOpen
    },
  },
  persist: false,
})
