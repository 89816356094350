import {usePinia} from '#imports'
import {useProfileStore} from '~/stores/profile'
import {useAuthStore} from '~/stores/auth'

export default defineNuxtPlugin(() => {
  addRouteMiddleware(
    'global-onboarding',
    async to => {
      const pinia = usePinia()
      const {getProfile} = useProfileStore(pinia)
      const authStore = useAuthStore(pinia)
      if (authStore.isAuthenticated && !authStore.isExpired) {
        // If the route is not auth, or onboarding
        const excludedRoutes = [
          '/auth',
          '/callback',
          '/onboarding/profile',
          '/onboarding/platforms',
          '/onboarding/3',
        ]

        const {data} = await getProfile()
        const isProfile = data.value !== null
        if (!isProfile && !excludedRoutes.includes(to.path)) {
          return navigateTo('/onboarding/profile', {redirectCode: 303})
        } else if (isProfile && excludedRoutes.includes(to.path)) {
          return navigateTo('/home', {redirectCode: 303})
        }
      }
    },
    {global: true}
  )
})
