export default {
  scrollBehavior: async function (to: any, from: any, savedPosition: any) {
    // const nuxtApp = useNuxtApp()
    // make sure the route has changed.
    // if (nuxtApp.$i18n && to.name !== from.name) {
    //   // `$i18n` is injected in the `setup` of the nuxtjs/i18n module.
    //   // `scrollBehavior` is guarded against being called even when it is not completed
    //   await nuxtApp.$i18n.waitForPendingLocaleChange()
    // }
    if (savedPosition) {
      return savedPosition
    }

    const findEl = async (hash: string, x = 0) => {
      return (
        (await document.querySelector(hash)) ||
        new Promise(resolve => {
          if (x > 50) {
            return resolve(document.querySelector('#app'))
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1))
          }, 100)
        })
      )
    }

    if (to.hash) {
      let el: any = await findEl(to.hash)
      const mainDiv = document.getElementsByTagName('main')[0].children[0]
      if ('scrollBehavior' in document.documentElement.style) {
        return mainDiv.scrollTo({
          top: el.offsetTop,
          behavior: 'smooth',
        })
      } else return mainDiv.scrollTo(0, el.offsetTop)
    }

    return { x: 0, y: 0 }
  },
}
