<template>
  <div class="flex min-h-screen flex-col bg-gray-900 pb-12 pt-16">
    <main
      class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
      <div class="flex flex-shrink-0 flex-col items-center justify-center">
        <NuxtLink class="inline-flex" to="/">
          <span class="sr-only">GameVerse</span>
          <img
            alt=""
            class="h-24 w-auto"
            src="~/assets/img/logos/gameverse-white-text.svg" />
        </NuxtLink>
      </div>
      <div class="py-16">
        <div class="text-center">
          <template v-if="error.statusCode === 404">
            <p
              class="text-sm font-semibold uppercase tracking-wide text-indigo-500">
              404 error
            </p>
            <h1
              class="mt-2 text-4xl font-extrabold tracking-tight text-gray-100 sm:text-5xl">
              Page not found.
            </h1>
            <p class="mt-2 text-base text-gray-400">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
          </template>
          <template v-else>
            <h1
              class="mt-2 text-4xl font-extrabold tracking-tight text-gray-100 sm:text-5xl">
              {{ error.statusCode }} error
            </h1>
            <p class="mt-2 text-base text-gray-400">
              {{ error.message }}
            </p>
          </template>
          <div
            class="mt-4 text-base font-medium text-indigo-500 hover:text-indigo-400"
            @click="handleError">
            <p class="cursor-pointer">
              Go back home <span aria-hidden="true"> &rarr;</span>
            </p>
          </div>
        </div>
      </div>
    </main>
    <footer class="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
      <nav class="flex justify-center space-x-4">
        <a
          class="text-sm font-medium text-gray-300 hover:text-gray-400"
          href="https://discord.com/invite/gameverse"
          >Discord</a
        >
        <span
          aria-hidden="true"
          class="inline-block border-l border-gray-600" />
        <a
          class="text-sm font-medium text-gray-300 hover:text-gray-400"
          href="https://twitter.com/GameverseApp"
          >Twitter</a
        >
      </nav>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError,
})

const handleError = async () => {
  await clearError({
    redirect: '/home',
  })
}

useHead({
  title: `Error ${props.error?.statusCode} - GameVerse`,
})

definePageMeta({
  layout: false,
})
</script>
