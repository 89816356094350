import {defineNuxtPlugin} from 'nuxt/app'
import {useWebSocket} from '@vueuse/core'
import {useAuthStore} from '~/stores/auth'
import {usePartiesStore} from '~/stores/parties'

export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore()
  const {id_token} = storeToRefs(authStore)
  const {apiUrl} = useRuntimeConfig().public
  const baseUrl = apiUrl.replace(/^https?:\/\//, '')
  const wsUrl = `wss://${baseUrl}/parties`
  const {messageListener} = usePartiesStore()

  watch(id_token, (newToken) => {
    if (authStore.isAuthenticated && newToken) {
      useWebSocket(`${wsUrl}?id_token=${newToken}`, {
        autoReconnect: true,
        heartbeat: {
          message: 'ping',
          interval: 60000
        },
        onConnected: () => console.log('WebSocket Connected'),
        onDisconnected: () => console.log('WebSocket Disconnected'),
        onMessage: async (ws, event) => messageListener(event)
      })
    }
  }, {
    immediate: true,
  })
})
