import { useNavigationStore } from '~/stores/navigation'
import { storeToRefs } from 'pinia'

export default defineNuxtRouteMiddleware((to, from) => {
  const navigationStore = useNavigationStore()

  const { tabs, inMessagesPage, bottomBar } = storeToRefs(navigationStore)

  tabs.value = []
  inMessagesPage.value = false
  bottomBar.value = true
})
