type CustomHeaders = Record<string, string>

interface UploadOptions {
  headers?: CustomHeaders
  onUploadProgress?: (event: ProgressEvent) => void
}

export function useFileUpload() {
  const uploadPercentage: Ref<number> = ref(0)

  const uploadFile = async (
    file: File,
    uploadUrl: string,
    options: UploadOptions = {}
  ): Promise<any> => {
    const formData = new FormData()
    formData.append('file', file)

    const { headers } = options

    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          ...headers,
        },
      })

      return await response.json()
    } catch (error) {
      throw error
    }
  }

  const uploadWithProgress = (
    file: File,
    uploadUrl: string,
    options: UploadOptions = {}
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', uploadUrl)

      if (options.headers) {
        Object.entries(options.headers).forEach(([key, value]) => {
          xhr.setRequestHeader(key, value)
        })
      }

      xhr.upload.onprogress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
          if (options.onUploadProgress) {
            options.onUploadProgress(event)
          }
        }
      }

      xhr.onload = () => {
        if (xhr.status === 200) {
          try {
            const data = xhr.responseText ? JSON.parse(xhr.responseText) : null
            resolve(data)
          } catch (e) {
            reject(e)
          }
        } else {
          reject(xhr.statusText)
        }
      }

      xhr.onerror = () => {
        reject('Error')
      }

      const formData = new FormData()
      formData.append('file', file)
      xhr.send(formData)
    })
  }

  return { uploadFile, uploadWithProgress, uploadPercentage }
}
