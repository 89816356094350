import { useAuthStore } from '@/stores/auth'
import { useIsQuerySet, usePinia } from '#imports'

export default defineNuxtPlugin(async () => {
  addRouteMiddleware(
    'global-auth',
    async (to, from) => {
      const authStore = useAuthStore(usePinia())

      if (authStore.isAuthenticated) {
        if (to.query.logout) {
          return
        }
        // Check if the token is expired and refresh it
        if (authStore.expires_at < Date.now()) {
          await authStore.refreshTokens()

          if (from.path !== to.path) {
            if (to.path === '/') {
              await navigateTo('/home')
            }
            await navigateTo(to.path)
          }
        }
      } else {
        // Handle authentication from query parameters
        if (to.query.code && to.query.scope && to.query.state) {
          return
        }
        if (
          useIsQuerySet(to.query.access_token) &&
          useIsQuerySet(to.query.refresh_token) &&
          useIsQuerySet(to.query.id_token)
        ) {
          const tokens = {
            access_token: to.query.access_token,
            refresh_token: to.query.refresh_token,
            id_token: to.query.id_token,
          }
          await authStore.setTokens(tokens)
          return navigateTo('/home')
        } else {
          // Redirect to the authentication page if not coming from there
          if (from.path !== '/auth' && to.path !== '/auth') {
            return navigateTo(`/auth`, {
              redirectCode: 303,
            })
          }
        }
      }
    },
    { global: true }
  )

  // Middleware for guest users
  addRouteMiddleware('guest', async () => {
    const store = useAuthStore(usePinia())
    if (store.isAuthenticated) {
      return navigateTo('/home')
    }
  })
})
