import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import nuxt_plugin_RZGiRwB5P6 from "/app/node_modules/nuxt-open-fetch/dist/runtime/nuxt-plugin.mjs";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_LDumGYo2KH from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import presets_1aypKNZ222 from "/app/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_7kPaTs6T4H from "/app/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_kQtglGecod from "/app/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import Vue3Lottie_client_2RwzQTxwz0 from "/app/src/plugins/Vue3Lottie.client.ts";
import auth_e0FkfVuy48 from "/app/src/plugins/auth.ts";
import deep_link_BuPEKbz9hL from "/app/src/plugins/deep-link.ts";
import onboarding_vVk5PP39vj from "/app/src/plugins/onboarding.ts";
import persistedstate_jMJU7HPQ1J from "/app/src/plugins/persistedstate.ts";
import sentry_client_KAXFuL2wum from "/app/src/plugins/sentry.client.ts";
import websocket_7Kf2ZBa9uV from "/app/src/plugins/websocket.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  nuxt_plugin_RZGiRwB5P6,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  presets_1aypKNZ222,
  scrollbars_client_7kPaTs6T4H,
  variables_kQtglGecod,
  Vue3Lottie_client_2RwzQTxwz0,
  auth_e0FkfVuy48,
  deep_link_BuPEKbz9hL,
  onboarding_vVk5PP39vj,
  persistedstate_jMJU7HPQ1J,
  sentry_client_KAXFuL2wum,
  websocket_7Kf2ZBa9uV
]