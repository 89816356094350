import { defineStore } from 'pinia'
import type { Mate, Leader } from '~/types'

let socket: WebSocket

export const usePartiesStore = defineStore('parties', {
  state: () => {
    return {
      mates: [] as Mate[],
      state: 'DISCONNECTED',
      parameters: {
        type: 'type',
      },
      games: {},
      selectedGame: 'Fortnite',
      invitationCode: '',
      leader: {} as Leader,
    }
  },
  getters: {},
  actions: {
    async leave() {
      await this.sendMessage(MessageType.PLAYER_LEFT, {})
    },

    async kickPlayer(id: string) {
      await this.sendMessage(MessageType.PLAYER_JOINED, { id })
    },

    async setLeader(id: string) {
      await this.sendMessage(MessageType.SET_LEADER, { id })
    },

    async joinWithCode(code: string) {
      await this.sendMessage(MessageType.JOIN_PARTY_WITH_CODE, { code })
    },

    async sendMessage(type: MessageType, payload: any) {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const message = JSON.stringify({ type, payload })
        socket.send(message)
      } else {
        console.error('Socket is not open. Cannot send message.')
      }
    },

    async messageListener(event: MessageEvent) {
      const parsedData = JSON.parse(event.data)
      switch (parsedData.type) {
        case 'connected':
          await this.handleConnected(parsedData.payload)
          break
        case 'player-disconnected':
          await this.handlePlayerDisconnected(parsedData.payload)
          break
        case 'join':
          await this.handleJoin(parsedData.payload)
          break
        case 'player-left':
          this.handlePlayerLeft(parsedData.payload)
          break
        case 'player-kicked':
          await this.handlePlayerKicked(parsedData.payload)
          break
        case 'leader-set':
          await this.handleLeaderSet(parsedData.payload)
          break
        default:
          console.error('Unhandled message type:', parsedData.type)
      }
    },

    async handleConnected(payload: Payload) {
      if (payload.party) {
        this.mates = payload.party.members.map(member => {
          return {
            id: member.userId,
          }
        })
        this.invitationCode = payload.party.invitationCode
        this.leader = {
          id: payload.party.leader.userId,
        }
      } else {
        console.warn('Received malformed "connected" payload:', payload)
      }
    },

    async handlePlayerDisconnected(payload: Payload) {
      const index = this.mates.findIndex(mate => mate.id === payload.player)
      if (index !== -1) {
        this.mates.splice(index, 1)
      }
    },

    async handleJoin(payload: Payload) {
      this.mates.push({
        id: payload.player as string,
      })
    },

    handlePlayerLeft(payload: Payload) {
      // TODO: Handle player left
    },

    async handlePlayerKicked(payload: Payload) {
      const index = this.mates.findIndex(mate => mate.id === payload.player)
      if (index !== -1) {
        this.mates.splice(index, 1)
      }
    },

    async handleLeaderSet(payload: Payload) {
      for (const mate of this.mates) {
        mate.isLeader = mate.id === payload.player
      }
    },
  },
})

enum MessageType {
  PARTY_CREATED = 'party-created',
  PLAYER_JOINED = 'player-joined',
  PLAYER_LEFT = 'player-left',
  JOINED = 'joined',
  REJOINED = 'rejoined',
  SET_LEADER = 'set-leader',
  INVALID_CODE = 'invalid-code',
  JOIN_PARTY_WITH_CODE = 'join-party-with-code',
}

type User = {
  userId: string
}

type Parties = {
  leader: User
  members: User[]
  invitationCode: string
}

type Payload = {
  party?: Parties
  player?: string
}
