import * as Sentry from '@sentry/vue'
import { browserTracingIntegration, replayIntegration } from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const { vueApp } = nuxtApp
  const router = useRouter()

  Sentry.init({
    app: [vueApp],
    dsn: 'https://b83e621b49733672711d68984da11af7@sentry.gameverse.app/4',
    environment: process.dev ? 'development' : 'production',
    tracePropagationTargets: [
      'gameverse.app',
      '*.gameverse.app',
      'localhost',
      'capacitor://localhost',
    ],
    integrations: [
      browserTracingIntegration({
        router: router,
      }),
      replayIntegration(),
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    dist: '~/.output',
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error(
          `[Exception handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        )
      }
      // Continue sending to Sentry
      return event
    },
  })

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    })
  )
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
