import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'

export default defineNuxtPlugin(() => {
  if (Capacitor.isNativePlatform()) {
    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      // Example url: gameverse.app/profile/123
      const slug = event.url.split('.app').pop() as string

      // We only push to the route if there is a slug present
      if (slug) {
        await navigateTo(slug)
        if (Capacitor.getPlatform() === 'ios') {
          await Browser.close()
        }
      }
    })
  }
})
