import { createUseOpenFetch } from '#imports'
import type { paths as ProfilePaths } from '#build/types/nuxt-open-fetch/schemas/profile.d.ts'

export type OpenFetchClientName = 'profile'

/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/useclientfetch}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useProfile = createUseOpenFetch<ProfilePaths>('profile')
/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/uselazyclientfetch}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useLazyProfile = createUseOpenFetch<ProfilePaths>('profile', true)
