import { defineStore } from 'pinia'
import type { Profile } from '~/types/Profile'
import { useFileUpload } from '~/composables/useFileUpload'

export const useProfileStore = defineStore('profile', {
  state: () => {
    return {
      profile: {} as Profile,
    }
  },
  getters: {},
  actions: {
    /**
     * Fetch the current user profile
     * @returns the profile
     */
    async getProfile() {
      const { id_token } = storeToRefs(useAuthStore())
      const response = await useLazyProfile('/profiles/me', {
        headers: {
          Authorization: `Bearer ${id_token.value}`,
        },
      })
      this.profile = response.data.value as Profile

      return response
    },

    /**
     * Create a new profile
     * @param profile
     * @returns the created profile
     */
    async createProfile(profile: Profile) {
      const { id_token } = storeToRefs(useAuthStore())
      return useLazyProfile('/profiles', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${id_token.value}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: profile,
      })
    },

    /**
     * Update the current user avatar
     * @param file
     * @param options
     * @returns the upload progress
     */
    async uploadAvatar(
      file: File,
      options: { onUploadProgress?: (event: ProgressEvent) => void }
    ) {
      const { uploadWithProgress } = useFileUpload()
      const { apiUrl } = useRuntimeConfig().public
      const { id_token } = storeToRefs(useAuthStore())

      return uploadWithProgress(file, `${apiUrl}/profiles/avatar`, {
        headers: {
          Authorization: `Bearer ${id_token.value}`,
        },
        ...options,
      })
    },
  },
})
