import { defineStore } from 'pinia' // Number of hours before the token expires

// Number of hours before the token expires
const TOKEN_EXPIRY_DURATION = 10
// Number of days before the refresh token expires
const REFRESH_TOKEN_EXPIRY_DURATION = 30

interface Tokens {
  access_token: string
  refresh_token: string
  id_token: string
}

export const useAuthStore = defineStore('auth', {
  persist: true,
  state: () => {
    return {
      codeVerifier: '',
      expires_at: 0,
      refresh_expires_at: 0,
      access_token: '',
      refresh_token: '',
      id_token: '',
    }
  },
  getters: {
    isAuthenticated(): boolean {
      return this.id_token !== ''
    },
    isExpired(): boolean {
      const now = new Date()
      return new Date(this.expires_at) < now
    },
    isRefreshExpired(): boolean {
      const now = new Date()
      return new Date(this.refresh_expires_at) < now
    },
  },
  actions: {
    async forceLogout() {
      this.expires_at = 0
      this.refresh_expires_at = 0
      this.access_token = ''
      this.refresh_token = ''
      this.id_token = ''
      return navigateTo('/auth')
    },
    async setTokens(tokens: Tokens) {
      const expiredAtDate = new Date()
      const refreshExpiredAtDate = new Date()
      // Set the expiry date to TOKEN_EXPIRY_DURATION hours from now
      expiredAtDate.setSeconds(
        expiredAtDate.getSeconds() + TOKEN_EXPIRY_DURATION
      )
      // Set the refresh expiry date to REFRESH_TOKEN_EXPIRY_DURATION days from now
      refreshExpiredAtDate.setDate(
        refreshExpiredAtDate.getDate() + REFRESH_TOKEN_EXPIRY_DURATION
      )
      this.expires_at = expiredAtDate.getTime()
      this.refresh_expires_at = refreshExpiredAtDate.getTime()
      this.access_token = tokens.access_token
      this.refresh_token = tokens.refresh_token
      this.id_token = tokens.id_token
    },
    async refreshTokens() {
      if (this.isRefreshExpired || this.refresh_token === '') {
        await this.forceLogout()
      }
      const { oauth2ClientId, oauth2ServerUrl } = useRuntimeConfig().public
      const params = new URLSearchParams()
      params.append('grant_type', 'refresh_token')
      params.append('client_id', oauth2ClientId)
      params.append('refresh_token', this.refresh_token)
      return $fetch<Tokens>(`${oauth2ServerUrl}/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params,
      })
        .then(async res => {
          await this.setTokens(res)
          return Promise.resolve()
        })
        .catch(async (err: any) => {
          console.error(err)
          if (err.response.status === 401 || err.response.status === 400) {
            await this.forceLogout()
          }
        })
    },
  },
})
