import { createPersistedState } from 'pinia-plugin-persistedstate'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  usePinia().use(
    createPersistedState({
      auto: true,
      storage: persistedState.localStorage,
    })
  )
})
